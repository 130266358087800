import React from 'react'
import Header from './Header';

const AboutMe:React.FC<{bg: string;navSelect:number,setPrev:(val:number) => void,setNav: (val: number) => void;}> = ({bg,navSelect,setPrev,setNav}) => {
  return (
    <div>
        <div
            className={
                "bg5 about-me-outer" 
            }
        >
            <Header navSelect={navSelect} setPrev={setPrev} setNav={setNav}/>
            <div className='row about-me'>
                <div className='profile'>
                    <div className='profile-container'>PROFILE:</div>
                    <div className='article about-me-text p'>Realtor | Listing Agent | North Fort Worth | Software Engineer | Tech<br/>
                        Follower of Christ | Reagan Republican | Father of Five | Motorcycle Enthusiast<br/><br/>
                        With a background in software and analytics, some of my first projects revolved around real estate. I bought and
                        sold several properties and have been passionate about real estate since I was young.<br/><br/>
                        The software and analytics skills that I utilize allows me to have a thorough undertanding of the market and
                        where your home fits in it. I will leverage the my skills and all of the tools in my toolkit to get your home
                        sold for what it's worth and in good time. 
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutMe