import React, { useState } from 'react'
import { bodyObj } from '../interfaces/interfaces'

const HomeSearchForm:React.FC<{
    body: bodyObj;
    legalNameValid: boolean;
    phoneValid: boolean;
    emailValid: boolean;
    selectedSqft:boolean;
    selectedAcre:boolean;
    selectedBeds:boolean;
    selectedBath:boolean;
    targetPriceValid:boolean;
    checked:boolean;
    setBody: (val: bodyObj) => void;
    legalNameCheck: (val: string) => void;
    phoneCheck: (val: string) => void;
    emailCheck: (val: string) => void;
    validPriceCheck:(val: string) => boolean;
    setTargetPriceValid: (val: boolean) => void;
    setSelectedSqft: (val: boolean) => void;
    setSelectedAcre: (val: boolean) => void;
    setSelectedBeds: (val: boolean) => void;
    setSelectedBath: (val: boolean) => void;
    setChecked: (val: boolean) => void;
    setUser:(v:any)=>void;
    submitForm: () => void;
    }> = ({
        body,
        legalNameValid,
        phoneValid,
        emailValid,
        selectedSqft,
        selectedAcre,
        selectedBeds,
        selectedBath,
        targetPriceValid,
        checked,
        setBody,
        legalNameCheck,
        phoneCheck,
        emailCheck,
        setTargetPriceValid,
        validPriceCheck,
        setSelectedSqft,
        setSelectedAcre,
        setSelectedBeds,
        setSelectedBath,
        setChecked,
        setUser,
        submitForm
    }) => {
  return (
    <div>
        <h3 className='home-form-header'>What does your ideal home look like?</h3>
        <form className='home-form-container'>  
            <div className='home-form-box'>
                <label>
                    <h4>Square Footage (under):</h4>
                    <select 
                        defaultValue={''}
                        className={selectedSqft ? "" : "red-bg"}
                        onChange={(e) => {
                            let sqft = e.target.value;
                            if(sqft.length){
                                setSelectedSqft(true)
                                setBody({ ...body, reason: body.reason.slice(0,1)+sqft+body.reason.slice(5)});
                            }                        
                        }}>
                        <option disabled value=''>Select...</option>                    
                        <option value="0500">500</option>
                        <option value="1000">1000</option>
                        <option value="1500">1500</option>
                        <option value="2000">2000</option>
                        <option value="2500">2500+</option>
                    </select>
                </label>
                <label>
                    <h4>Beds (at least):</h4>
                    <select 
                        defaultValue={''}
                        className={selectedBeds ? "" : "red-bg"}
                        onChange={(e) => {
                            let beds = e.target.value;
                            if(beds.length){
                                setSelectedBeds(true)
                                setBody({ ...body, reason: body.reason.slice(0,9)+beds+body.reason.slice(10)});
                            }
                        }}>
                        <option disabled  value="">Select...</option>
                        <option value="0">Studio</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5+</option>
                    </select>
                </label>
            </div>
            <div className='home-form-box'>
                <label>
                    <h4>Preferred Acreage (under):</h4>
                    <select 
                        defaultValue={''}
                        className={selectedAcre ? "" : "red-bg"}
                        onChange={(e) => {
                            let acre = e.target.value;
                            if (acre.length){
                                setSelectedAcre(true)
                                setBody({ ...body, reason: body.reason.slice(0,5)+acre+body.reason.slice(9)});
                            }
                        }}>
                        <option disabled  value="">Select...</option>                    
                        <option value=".125">Small yard - about 1/8 acre</option>
                        <option value=".250">Medium yard - about 1/4 acre</option>
                        <option value=".375">Large yard - about 3/8 acre</option>
                        <option value=".500">Larger yard - about 1/2 acre</option>
                        <option value=".999">More land is better</option>
                    </select>
                </label>
                <label>
                    <h4>Baths (at least):</h4>
                    <select 
                        defaultValue={''}
                        className={selectedBath ? "" : "red-bg"}
                        onChange={(e) => {
                            let bath = e.target.value;
                            if (bath.length){
                                setSelectedBath(true)
                                setBody({ ...body, reason: body.reason.slice(0,10)+bath+body.reason.slice(13)});
                            }
                        }}>
                        <option disabled value="">Select...</option>                    
                        <option value="1.0">1</option>
                        <option value="1.5">1.5</option>
                        <option value="2.0">2</option>
                        <option value="2.5">2.5</option>
                        <option value="3.0">3+</option>
                    </select>
                </label>
            </div>
            <div className="home-form-box">
                <label className='row'>
                    <h4>Target Price (under):</h4>
                    <input
                        id="maxLoan"
                        className={targetPriceValid ? "" : "red-bg"}
                        onChange={(e) => {
                            if(parseInt(e.target.value)){
                                setBody({ ...body, reason: body.reason.slice(0,13)+ e.target.value });
                            } else {
                                setBody({ ...body, reason: body.reason.slice(0,13)+ '0'});
                            }
                            validPriceCheck(e.target.value)
                        }}
                        value={parseInt(body.reason.slice(13))}
                    />
                </label>
            </div>
            <div className='home-form-box'>
                <label id='preapproved-checkbox'>
                    <input onChange={(e) => {
                            const value = checked ? "N" : "Y"
                            setChecked(!checked)
                            setBody({...body,reason: value + body.reason.slice(1)})
                        }} checked={checked} type='checkbox'/>
                        <h4>I am preapprove and have the down payment</h4>
                </label>
            </div>
            <div className='home-form-box'>
                <label className="home-form-row row">
                    <h4>Full Legal Name:</h4>
                    <input
                    id="legal_name"
                    className={legalNameValid ? "" : "red-bg"}
                    onChange={(e) => {
                        if (e.target.value.length <= 40) {
                            setBody({ ...body, legal_name: e.target.value });
                            legalNameCheck(e.target.value);
                        } else
                            alert("Please use middle initials rather than full middle name.");
                        }
                    }
                    />
                </label>
            </div>
            <div className='home-form-box'>
                <label className="home-form-row row">
                    <h4>Preferred Name:</h4>
                    <input
                        id="preferred_name"
                        onChange={(e) => {
                        if (e.target.value.length <= 15) {
                            setBody({ ...body, preferred_name: e.target.value });
                        }
                    }}
                    value={body.preferred_name}
                    />
                </label>
            </div>
            <div className='home-form-box'>
                <label className="home-form-row row">
                    <h4>Phone:</h4>
                    <input
                        id="phone"
                        className={phoneValid ? "" : "red-bg"}
                        onChange={(e) => {
                        if (
                        e.target.value
                            .replaceAll("-", "")
                            .replace("(", "")
                            .replace(")", "")
                            .replaceAll(" ", "")
                            .trim().length <= 10
                        ) {
                            setBody({ ...body, phone: e.target.value });
                            phoneCheck(e.target.value);
                        }
                    }}
                    value={body.phone}
                    />
                </label>
            </div>
            <div className='home-form-box'>
                <label className="home-form-row row">
                    <h4>Email:</h4>
                    <input
                    id="email"
                    className={emailValid ? "" : "red-bg"}
                    onChange={(e) => {
                        if (e.target.value.length <= 50) {
                        setBody({ ...body, email: e.target.value });
                        emailCheck(e.target.value);
                        } else {
                        alert(
                            "Please use another email, as the maximum length for this field is 50 characters."
                        );
                        }
                    }}
                    value={body.email}
                    />
                </label>
            </div>
        </form>
    </div>
  )
}

export default HomeSearchForm