import React, { useState } from "react";

const MortgageCalculator = () => {
    const [rate, setRate] = useState("");
    const [principle, setPrinciple] = useState("");
    const [years, setYears] = useState("30");
    const [taxes, setTaxes] = useState("");
    const [insurance, setInsurance] = useState("");
    const parsePercent = (val: string) => {
        let temp = "";
        for (let i = 0; i < val.length; i++) {
            if (
                [
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    ".",
                ].includes(val[i])
            ) {
                temp += val[i];
            }
        }
        if (parseFloat(temp) > 1) {
            return parseFloat(temp) / 100;
        } else return parseFloat(temp);
    };
    return (
        <div className="col calc">
            <h4>Mortgage Payment Calculator</h4>
            <label className="font20">
                Term:{" "}
                <select
                    defaultValue={"30"}
                    onChange={(e) => {
                        setYears(e.target.value);
                    }}
                >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                </select>
            </label>
            <label className="font20">
                Rate:{" "}
                <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setRate(e.target.value);
                    }}
                    value={rate}
                />
            </label>
            <label className="font20">
                Principle:{" "}
                <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPrinciple(e.target.value);
                    }}
                    value={principle}
                />
            </label>
            <label className="font20">
                Annual Taxes:{" "}
                <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setTaxes(e.target.value);
                    }}
                    value={taxes}
                />
            </label>
            <label className="font20">
                Annual Insurance Premium:{" "}
                <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInsurance(e.target.value);
                    }}
                    value={insurance}
                />
            </label>
            <label className="font20">
                Estimated Monthly Payment:
                <span>
                    ${((Math.round(
  parseInt(principle) * (parsePercent(rate) / 12) /
  (1 - (1 / (1 + parsePercent(rate) / 12) ** (parseInt(years) * 12)))
) + (parseFloat(taxes) /12 || 0) + (parseFloat(insurance)|| 0))/12 || 0).toLocaleString()}
                </span>
            </label>
        </div>
    );
};

export default MortgageCalculator;
