import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { inject } from "@vercel/analytics";
import { SpeedInsights } from "@vercel/speed-insights/react"
import Article from "./Article";
import Content from "./Content";
import Home from "./Home";
import Inquiry from "./Inquiry";
import Landing from "./Landing";
import nav from './constants/nav'
import Schedule from "./Schedule";
import AboutMe from "./AboutMe";

const articles = require('./content/articles.json')

function App() {
  inject()
  const [navSelect, setNav] = useState(0);
  const [navPrev, setPrev] = useState(0)
  const [article, setArticle] = useState(0)
  const [load,setLoad] = useState(true)
  const [user,setUser] = useState(null)
  const bg = useRef("bg" + Math.ceil(Math.random() * 5));
  useEffect(()=>{
    let current = Math.ceil(Math.random() * 5)
    if(bg.current === "bg" + current){
      bg.current = "bg"+(Math.abs(current-3)+1)
    } else {
      bg.current = "bg"+current
    }
    setLoad(true)
    setTimeout(()=>{
      setLoad(false)
    },Math.ceil(Math.random() * 200)+300)
  },[navSelect])
  if(window.location.href.includes('?')){
    if(process.env.NODE_ENV == 'development'){
      window.location.href = 'http://localhost:3000'
    } else{
      window.location.href = 'https://skyledodd.com'
    }
  }
  return (
    <div id={![0,1,2,3,4,5].includes(navSelect) ? "main" : ""}>
      <SpeedInsights/>
      {load &&<><div className='loader'></div>
      <div className={load ? "bg-gray": ""}></div></>}
      {navSelect === nav.LANDING && !load && <Landing navSelect={navSelect} bg={bg.current} setPrev={setPrev} setNav={setNav} />}
      {navSelect === nav.CONTENT && !load && <Content bg={bg.current} navPrev={navPrev} articles={articles} setNav={setNav} setArticle={setArticle}/>}
      {navSelect === nav.ARTICLE && !load && <Article bg={bg.current} article={articles[article]} setNav={setNav} />}
      {navSelect === nav.INQUIRY && !load && <Inquiry bg={bg.current} navPrev={navPrev} setNav={setNav} setUser={setUser} />}
      {navSelect === nav.SCHEDULE && !load && <Schedule bg={bg.current} navPrev={navPrev} user={user} setNav={setNav} />}
      {navSelect === nav.ABOUTME && !load && <AboutMe bg={bg.current} navSelect={navSelect} setPrev={setPrev} setNav={setNav} />}
      {![0,1,2,3,4,5].includes(navSelect) && !load && <Home navSelect={navSelect} bg={bg.current} setPrev={setPrev} setNav={setNav} setUser={setUser} />}
      
    </div>
  );
}

export default App;
