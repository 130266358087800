import React from 'react'

const Table: React.FC<{matrix:Array<Array<string>>}> = ({ matrix }) => {

    return (
      <table className='table'>
        <thead>
          <tr>
            {matrix[0].map((header) => 
              <th key={header}>{header}</th>
            )}
          </tr>
            {
                matrix.slice(1).map((r,i)=>{
                    if(i%2){
                        return <tr className='gray'>{r.map((v)=><td className='td'>{v}</td>)}</tr>
                    }
                    else{
                        return <tr className='main'>{r.map((v)=><td className='td'>{v}</td>)}</tr>
                    }

                }
                )
            }
        </thead>
      </table>
    );
  };

export default Table