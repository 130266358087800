import React, { useState } from 'react'
import Plot from 'react-plotly.js';

const ValuesChart = () => {
    const [showExplanation,setShowExplanation] = useState(false)
    const homeOwnershipValues = [2, 6, 6, 2];
    const rentingValues = [4,2,2,4];
    return (<div>There is a bubble chart that 
        demonstrates the importance of benefits 
        associated with leasing vs purchasing and 
        the time to realize those benefits on the 
        desktop site, but it can't be displayed 
        on a screen this size. The absolute time 
        is notrepresented, as we can't put hard 
        numbers, but rather time relative to the 
        comparison. Here is an exmplaination of 
        that chart.
        <br/><br/>The flexibility that comes from
        renting is not as quickly realized as 
        buying a home leads to stability. A lease 
        is for a specified period of time, and 
        thus getting out of the lease without any 
        negative consequences does take the 
        period of time the lease is written for. 
        The stability of a home purchase on the  
        otherhand is almost immediate. Once 
        purchased, the home belongs to the home  
        owner, unless they default on the 
        mortgage or don't pay the taxes, etc.
        <br/><br/>Inlation, usually, is a slow
        accumulation. In 20 years your mortgage  
        payment, if you haven't paid it off, will
        be the same as it was 20 years earlier. 
        At that point it will, almost certainly, 
        cost less than the rent price of a 
        similar property. Generally, renting will 
        mean lower insurance costs, avoiding 
        homeowners insurance altogether and 
        utilities are shared in some sense. For 
        example, the cost of heating and cooling 
        an apartment is positively impacted by 
        the fact that two to three walls will 
        face other units that are also heated or 
        cooled, reducing cost.
        <br/><br/>It takes much longer to gain 
        equity in a home, than it does to be 
        able to exit a lease. Compared with home 
        ownership though, breaking a lease is 
        much easier and less costly than getting 
        out of the mortgaged home.
        <br/><br/>To realize the impact of 
        not making repairs would also generally 
        be a benefit that is realized when 
        something breaks, which is unlikely to 
        be shortly after move in. A purchased 
        home is immediately customizable, as 
        long as customizations don't violate 
        city ordinances,HOA bylaws, etc.</div>
  )
}

export default ValuesChart