import React from 'react'
import "./App.css"
import Logo from './Logo'

const Banner: React.FC<{bg:string}> = ({bg}): JSX.Element => {

  return (
    <div>
    <div className={'bg0'+bg.slice(2)+'-banner'}></div>
    <div className="banner-header"><div className='logo-top'><Logo/></div><h1>S. Kyle Dodd</h1></div>
    
    </div>
  )
}

export default Banner