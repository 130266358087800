
const Logo = () => {
  return (
      <svg
        className='nav-btn-logo'
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 563.03119 564.6615"
        height="65"
        width="65"
        id="svg2"
        version="1.1"
      >
        <defs id="defs6">
          <clipPath id="clipPath20" clipPathUnits="userSpaceOnUse">
            <path id="path18" d="M 36,36 H 386.2734 V 387.4961 H 36 Z" />
          </clipPath>
          <clipPath id="clipPath30" clipPathUnits="userSpaceOnUse">
            <path
              id="path28"
              d="m 55.33897,149.9518 h 90.47797 V 289.5444 H 55.33897 Z"
            />
          </clipPath>
          <clipPath id="clipPath44" clipPathUnits="userSpaceOnUse">
            <path
              id="path42"
              d="m 74.91582,77.52861 h 181.0379 v 268.4389 H 74.91582 Z"
            />
          </clipPath>
          <clipPath id="clipPath58" clipPathUnits="userSpaceOnUse">
            <path
              id="path56"
              d="m 138.3979,24.01066 h 267.7713 v 391.4748 H 138.3979 Z"
            />
          </clipPath>
          <clipPath id="clipPath72" clipPathUnits="userSpaceOnUse">
            <path
              id="path70"
              d="m 177.0158,65.23465 h 190.5354 v 309.0268 H 177.0158 Z"
            />
          </clipPath>
          <clipPath id="clipPath86" clipPathUnits="userSpaceOnUse">
            <path
              id="path84"
              d="m 182.3229,73.48868 h 179.9212 v 292.5187 H 182.3229 Z"
            />
          </clipPath>
        </defs>
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,564.66147)" id="g10">
          <g id="g12" />
          <g id="g14">
            <g clip-path="url(#clipPath20)" id="g16">
              <path
                id="path22"
                style={{fill:'#2c8080',fillOpacity:1,fillRule:'nonzero','stroke':'none'}}
                d="M 37,211.748 211.1367,37 385.2734,211.748 211.1367,386.4961 Z"
              />
            </g>
          </g>
          <g id="g24">
            <g clip-path="url(#clipPath30)" id="g26">
              <g transform="translate(75.61076,189.9379)" id="g32">
                <text
                  id="text36"
                  style={{fontVariant:'normal',fontFamily:'Helvetica,Arial,sans-serif',fontWeight:'normal',fontSize:'79.12236023px',fill:'#f5de80',fillOpacity:1,fillRule:'nonzero',stroke:'none'}}
                  transform="scale(1,-1)"
                >
                  <tspan id="tspan34" y="0" x="0">
                    S
                  </tspan>
                </text>
              </g>
            </g>
          </g>
          <g id="g38">
            <g clip-path="url(#clipPath44)" id="g40">
              <g transform="translate(119.8254,154.2859)" id="g46">
                <text
                  id="text50"
                  style={{fontVariant:'normal',fontFamily:'Helvetica,Arial,sans-serif',fontWeight:'normal',fontSize:'151.88290405px',fill:'#f5de80',fillOpacity:1,fillRule:'nonzero',stroke:'none'}}
                  transform="scale(1,-1)"
                >
                  <tspan id="tspan48" y="0" x="0">
                    K
                  </tspan>
                </text>
              </g>
            </g>
          </g>
          <g id="g52">
            <g clip-path="url(#clipPath58)" id="g54">
              <g transform="translate(197.0928,135.9853)" id="g60">
                <text
                  id="text64"
                  style={{fontVariant:'normal',fontFamily:'Helvetica,Arial,sans-serif',fontWeight:'normal',fontSize:'215.56910706px',fill:'#f5de80',fillOpacity:1,fillRule:'nonzero',stroke:'none'}}
                  transform="scale(1,-1)"
                >
                  <tspan id="tspan62" y="0" x="0">
                    D
                  </tspan>
                </text>
              </g>
            </g>
          </g>
          <g id="g80">
            <g clip-path="url(#clipPath86)" id="g82">
              <g transform="translate(219.2263,157.1801)" id="g88">
                <text
                  id="text92"
                  style={{fontVariant:'normal',fontFamily:'Helvetica,Arial,sans-serif',fontWeight:'normal',fontSize:'159.60389709px',fill:'#2c8080',fillOpacity:1,fillRule:'nonzero',stroke:'none'}}
                  transform="scale(1,-1)"
                >
                  <tspan id="tspan90" y="0" x="0">
                    D
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </svg>
  );
};

export default Logo;
