import { bodyObj } from "../interfaces/interfaces";

export const submitInquiryForm = (body: bodyObj):Promise<any> => {
    const data = {
        ...body,
        reason: body.reason
            .replaceAll("<", "")
            .replaceAll(">", "")
            .replaceAll(";", ".")
            .replaceAll("'", '"'),
        phone: body.phone
            .replaceAll("-", "")
            .replace("(", "")
            .replace(")", "")
            .replaceAll(" ", "")
            .trim(),
    };
    return fetch("https://mathemagical-server.herokuapp.com/inquiry", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
}