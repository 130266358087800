import React from 'react'
import { ImCalculator } from "react-icons/im";
import { AiOutlineStock } from "react-icons/ai";
import { TbPigMoney } from "react-icons/tb";
import { GrTechnology } from "react-icons/gr";
import { GiFamilyHouse } from 'react-icons/gi';
import { GiTexas } from "react-icons/gi";
import { FaMapSigns } from "react-icons/fa";
import nav from '../constants/nav';
import { handleClick, setClass } from '../helpers/setClassHelpers';


const HomeSubNav: React.FC<{subNavSelect: number}> = ({subNavSelect}) => {
    
  return (
    <nav>
        <div id='tt-10' className = 'tooltip'>
            <span id='st-10' className='tooltiptext'>Main</span>
            <button id='bt-10' className={setClass(subNavSelect,nav.MAIN)}>
                <GiFamilyHouse size={36}/>
            </button>
        </div>
        <div id='tt-11' className = 'tooltip'>
            <span id='st-11' className='tooltiptext'>Trends</span>
            <button id='bt-11' className={setClass(subNavSelect,nav.HOME_TREND)}>
                <AiOutlineStock size={36} />
            </button>
        </div>  
        <div id='tt-12' className = 'tooltip'>
            <span id='st-12' className='tooltiptext'>Mortgage Calculator</span>
            <button id='bt-12' className={setClass(subNavSelect,nav.MORT_CALC)}>
                <ImCalculator size={36} />
            </button>
        </div>
        <div id='tt-13' className = 'tooltip'>
            <span id='st-13' className='tooltiptext'>Value</span>
            <button id='bt-13' className={setClass(subNavSelect,nav.HOME_BENE)}>
                <TbPigMoney size={36} />
            </button>
        </div>
        <div id='tt-14' className = 'tooltip'>
            <span id='st-14' className='tooltiptext'>Tech</span>
            <button id='bt-14' className={setClass(subNavSelect,nav.HOME_TECH)}>
                <GrTechnology size={36} />
            </button>
        </div>
        <div id='tt-15' className = 'tooltip'>
            <span id='st-15' className='tooltiptext'>Location</span>
            <button id='bt-15' className={setClass(subNavSelect,nav.LOCATION)}>
                <FaMapSigns size={36} />
            </button>
        </div>
    </nav>
    )
}

export default HomeSubNav