import React, { useState } from "react";
import Banner from "./Banner";
import Search from "./Search";
import "./App.css";

const Content: React.FC<{
    bg: string;
    navPrev: number;
    articles: Array<Array<string>>;
    setNav: (val: number) => void;
    setArticle: (val: number) => void;
}> = ({ bg, navPrev, articles, setNav, setArticle }): JSX.Element => {
    const [searchInput, setSearchInput] = useState("");
    const [tag, setTag] = useState("");
    const [start, setStart] = useState(0);
    let result_num = 0;
    const latest: Array<Array<string>> = []
    articles.forEach(a=>{
        latest.unshift(a)
    })
    const listing = latest.map((a: Array<string>, i: number) => {
        if (a[a.length - 1].includes(tag)) {
            if (
                a
                    .join(" ")
                    .toLowerCase()
                    .replace(",", "")
                    .replace('"', "")
                    .replace("'", "")
                    .includes(searchInput.toLowerCase())
            ) {
                result_num++;
                if (result_num >= start && result_num <= 10 + start) {
                    return (
                        <p>
                            <span className="row-num">{result_num + "."}</span>
                            <span
                                key={i}
                                className="article-title p"
                                onClick={() => {
                                    setArticle(i);
                                    setNav(2);
                                }}
                            >
                                {a[0]}
                            </span>
                            <div key={"article-" + i}>
                                <span className="b">
                                    {a[a.length - 1].slice(0, 5)}
                                </span>
                                &emsp;
                                <span className="i">
                                    {a[a.length - 1].slice(8)}
                                </span>
                            </div>
                        </p>
                    );
                }
            }
        }
    });
    return (
        <div className="bg-gradient banner-outer">
            <Banner bg={bg} />
            <div className="search-row">
                <button className="back-btn" onClick={() => setNav(navPrev)}>
                    {"< Back"}
                </button>
                <Search
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                />
                <div>
                    Filter:{" "}
                    <select
                        className="tag-filter"
                        onChange={(e) => setTag(e.target.value)}
                    >
                        <option value="">All</option>
                        <option value="life insurance">Life Insurance</option>
                        <option value="location">Location</option>
                        <option value="real estate">Real Estate</option>
                    </select>
                </div>
            </div>
            <div className="content-box scroll">{listing}</div>
            <div className="page-nav-row content-center">
                {(start >= 10) ?
                <button
                    className="next-page"
                    onClick={() => {
                        setStart(start - 10);
                    }}
                >
                    {"< 10"}
                </button>:
                <button
                    className="next-page-disabled"
                >
                    {"< 10"}
                </button>
                }
                <div className="white-w30-center">
                    <div className="center-num">{start / 10 + 1}</div>
                </div>
                {result_num > start + 10 ?
                <button
                className="next-page"
                    onClick={() => {
                        setStart(start + 10);
                    }}
                >
                    {"10 >"}
                </button>
                :<button
                className="next-page-disabled"
            >
                {"10 >"}
            </button>
                }
            </div>
        </div>
    );
};

export default Content;
