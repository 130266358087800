import React, { MutableRefObject, useRef } from 'react';
import { GoogleMap, useLoadScript, PolygonF, Libraries } from '@react-google-maps/api';

const App: React.FC = () => {
  const mapContainerStyle = {
    width: '60vw',
    height: '90vh',
  };

  const center = {
    lat: 32.875,
    lng: -97.33697406078338,
  };

  const libraries: MutableRefObject<Libraries> = useRef(['places']);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || 'AIzaSyBQK0gQdXO5jfZThhCFND45UGDVfwYCLl4',
    libraries:libraries.current,
  });

  const polygonPathK = [
    { lat: 33.25, lng: -97.5 },
    { lat: 33.25, lng: -97.6 },
    { lat: 32.95, lng: -97.6 },
    { lat: 32.95, lng: -97.5 },
    { lat: 33.05, lng: -97.5 },
    { lat: 32.95, lng: -97.4 },
    { lat: 32.95, lng: -97.3 },
    { lat: 33.1, lng: -97.45 },
    { lat: 33.25, lng: -97.3 },
    { lat: 33.25, lng: -97.4 },
    { lat: 33.15, lng: -97.5 },
  ];
  const polygonPathY=[
    
    { lat: 33.1, lng: -97.4 },
    { lat: 32.95, lng: -97.25 },
    { lat: 32.8, lng: -97.25 },
    { lat: 32.8, lng: -97.15 },
    { lat: 32.95, lng: -97.15 },
    { lat: 33.1, lng: -97.0 },
    { lat: 33.1, lng: -97.1 },
    { lat: 33., lng: -97.2 },
    { lat: 33.1, lng: -97.3 },
  ]
  const polygonPathL=[
    { lat: 32.93, lng: -97.35 },
    { lat: 32.93, lng: -97.45 },
    { lat: 32.62, lng: -97.45 },
    { lat: 32.62, lng: -97.3 },
    { lat: 32.48, lng: -97.3 },
    { lat: 32.48, lng: -97.05 },
    { lat: 32.55, lng: -97.05 },
    { lat: 32.55, lng: -97.2 },
    { lat: 32.62, lng: -97.2 },
    { lat: 32.62, lng: -97.15 },
    { lat: 32.68, lng: -97.15 },
    { lat: 32.68, lng: -97.2 },
    { lat: 32.72, lng: -97.2 },
    { lat: 32.72, lng: -97.05 },
    { lat: 32.78, lng: -97.05 },
    { lat: 32.78, lng: -97.3 },
    { lat: 32.68, lng: -97.3 },
    { lat: 32.68, lng: -97.35 },
  ]

  const polygonPathDiamond=[
    { lat: 33.35, lng: -97.65 },
    { lat: 33.15, lng: -96.9 },
    { lat: 32.4, lng: -97. },
    { lat: 32.5, lng: -97.6 },
  ]

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <h4 className='map-header'>Service Area</h4>
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={10}
      center={center}
    ><PolygonF
      path={polygonPathDiamond}
      options={{
        fillColor: 'cornflowerblue',
        fillOpacity: 0.4,
        strokeColor: '#2c8080',
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex:-1
      }}
    />
      <PolygonF
        path={polygonPathK}
        options={{
          fillColor: 'wheat',
          fillOpacity: 0.7,
          strokeColor: '#2c8080',
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />
      <PolygonF
        path={polygonPathY}
        options={{
          fillColor: 'wheat',
          fillOpacity: 0.7,
          strokeColor: '#2c8080',
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />
      <PolygonF
        path={polygonPathL}
        options={{
          fillColor: 'wheat',
          fillOpacity: 0.7,
          strokeColor: '#2c8080',
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />
    </GoogleMap>
    </div>
  );
};

export default App;
