import React from 'react'
import { ImCalculator } from "react-icons/im";
import { AiOutlineStock } from "react-icons/ai";
import { TbHomeDollar, TbPigMoney } from "react-icons/tb";
import { GrTechnology } from "react-icons/gr";
import { GiFamilyHouse } from 'react-icons/gi';
import { GiTexas } from "react-icons/gi";
import { FaMapSigns } from "react-icons/fa";
import nav from '../constants/nav';
import { handleClick, setClass } from '../helpers/setClassHelpers';
import { FcMindMap } from "react-icons/fc";
import { FaSignHanging } from "react-icons/fa6";
import { IoPricetagsOutline } from "react-icons/io5";


const ListSubNav: React.FC<{subNavSelect: number}> = ({subNavSelect}) => {
    
  return (
    <nav>
        <div id='tt-16' className = 'tooltip'>
            <span id='st-16' className='tooltiptext'>Main</span>
            <button id='bt-16' className={setClass(subNavSelect,nav.SELL)}>
                <TbHomeDollar size={36}/>
            </button>
        </div>
        <div id='tt-17' className = 'tooltip'>
            <span id='st-17' className='tooltiptext'>Market Analysis</span>
            <button id='bt-17' className={setClass(subNavSelect,nav.CMA)}>
                <FcMindMap size={36} />
            </button>
        </div>  
        <div id='tt-18' className = 'tooltip'>
            <span id='st-18' className='tooltiptext'>Marketing</span>
            <button id='bt-18' className={setClass(subNavSelect,nav.MARKETING)}>
                <FaSignHanging size={36} />
            </button>
        </div>
        <div id='tt-19' className = 'tooltip'>
            <span id='st-19' className='tooltiptext'>List Price</span>
            <button id='bt-19' className={setClass(subNavSelect,nav.PRICING)}>
                <IoPricetagsOutline size={36} />
            </button>
        </div>
        <div id='tt-14' className = 'tooltip'>
            <span id='st-14' className='tooltiptext'>Tech</span>
            <button id='bt-14' className={setClass(subNavSelect,nav.HOME_TECH)}>
                <GrTechnology size={36} />
            </button>
        </div>
        <div id='tt-15' className = 'tooltip'>
            <span id='st-15' className='tooltiptext'>Location</span>
            <button id='bt-15' className={setClass(subNavSelect,nav.LOCATION)}>
                <FaMapSigns size={36} />
            </button>
        </div>
    </nav>
    )
}

export default ListSubNav