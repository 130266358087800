
const Exp = () => {
    return (<svg id="eXpLogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 277.4 144">
    <defs>
      <style>
        .cls-1 {
          `fill: #19469d;
          stroke-width: 0px;`
        }
      </style>
    </defs>
    <g>
      <path className="cls-1" d="m20.99,69.48c.7,6.3,3.15,11.19,7.35,14.69,4.2,3.5,9.27,5.25,15.22,5.25,5.25,0,9.65-1.08,13.21-3.24,3.55-2.15,6.67-4.87,9.36-8.13l15.04,11.37c-4.9,6.07-10.38,10.38-16.44,12.94-6.07,2.56-12.42,3.85-19.07,3.85-6.3,0-12.25-1.05-17.84-3.15-5.6-2.1-10.44-5.07-14.52-8.92-4.08-3.85-7.32-8.48-9.71-13.9-2.39-5.42-3.59-11.46-3.59-18.11s1.19-12.68,3.59-18.11c2.39-5.42,5.62-10.06,9.71-13.91,4.08-3.85,8.92-6.82,14.52-8.92,5.6-2.1,11.55-3.15,17.84-3.15,5.83,0,11.16,1.02,16,3.06,4.84,2.04,8.98,4.98,12.42,8.83,3.44,3.85,6.12,8.6,8.05,14.26,1.92,5.66,2.89,12.16,2.89,19.5v5.77H20.99Zm43.03-15.74c-.12-6.3-2.04-11.19-5.77-14.69-3.73-3.5-8.92-5.25-15.57-5.25-6.3,0-11.28,1.81-14.96,5.42-3.67,3.62-5.92,8.46-6.73,14.52h43.03Z"/>
      <path className="cls-1" d="m186.79,20.15h19.94v12.59h.35c3.03-5.25,7.11-9.01,12.24-11.28,5.13-2.27,10.67-3.41,16.62-3.41,6.41,0,12.18,1.14,17.32,3.41,5.13,2.27,9.5,5.4,13.12,9.36,3.61,3.97,6.35,8.63,8.22,13.99,1.86,5.37,2.8,11.14,2.8,17.32s-.99,11.96-2.97,17.32c-1.98,5.37-4.75,10.03-8.31,13.99-3.56,3.97-7.84,7.09-12.86,9.36-5.01,2.27-10.49,3.41-16.44,3.41-3.85,0-7.32-.41-10.41-1.22-3.09-.82-5.8-1.86-8.13-3.15-2.33-1.28-4.32-2.65-5.95-4.11-1.63-1.46-2.97-2.89-4.02-4.29h-.52v50.55h-20.99V20.15Zm69.62,41.98c0-3.26-.55-6.41-1.66-9.44-1.11-3.03-2.74-5.71-4.9-8.05-2.16-2.33-4.75-4.2-7.78-5.6-3.03-1.4-6.47-2.1-10.32-2.1s-7.29.7-10.32,2.1c-3.03,1.4-5.63,3.27-7.78,5.6-2.16,2.34-3.79,5.02-4.9,8.05-1.11,3.03-1.66,6.18-1.66,9.44s.55,6.42,1.66,9.44c1.11,3.04,2.74,5.72,4.9,8.05,2.16,2.33,4.75,4.2,7.78,5.6,3.03,1.4,6.47,2.1,10.32,2.1s7.29-.7,10.32-2.1c3.03-1.4,5.62-3.26,7.78-5.6,2.16-2.33,3.79-5.01,4.9-8.05,1.11-3.03,1.66-6.18,1.66-9.44Z"/>
      <polygon className="cls-1" points="144.25 62.13 176.27 104.11 150.74 104.11 131.46 78.85 112.23 104.11 86.69 104.11 166.09 0 191.62 0 144.25 62.13"/>
      <polygon className="cls-1" points="112.24 20.15 86.7 20.15 112.54 54.07 125.29 37.31 112.24 20.15"/>
      <g>
        <path className="cls-1" d="m19.4,119.84c0-.32.26-.61.61-.61h9.2c3.86,0,7.02,3.09,7.02,6.92,0,2.96-1.96,5.37-4.76,6.5l4.41,8.17c.23.42,0,.93-.55.93h-3.38c-.29,0-.45-.16-.52-.29l-4.28-8.53h-3.57v8.21c0,.32-.29.61-.61.61h-2.96c-.35,0-.61-.29-.61-.61v-21.3Zm9.46,9.65c1.71,0,3.19-1.48,3.19-3.28,0-1.71-1.48-3.12-3.19-3.12h-5.24v6.4h5.24Z"/>
        <path className="cls-1" d="m49.83,119.84c0-.32.26-.61.61-.61h13.1c.35,0,.61.29.61.61v2.64c0,.32-.26.61-.61.61h-9.53v5.28h7.95c.32,0,.61.29.61.61v2.67c0,.35-.29.61-.61.61h-7.95v5.63h9.53c.35,0,.61.29.61.61v2.64c0,.32-.26.61-.61.61h-13.1c-.35,0-.61-.29-.61-.61v-21.3Z"/>
        <path className="cls-1" d="m72.9,140.92l10.01-21.66c.1-.19.26-.35.55-.35h.32c.32,0,.45.16.55.35l9.91,21.66c.19.42-.06.84-.55.84h-2.8c-.48,0-.71-.19-.93-.64l-1.58-3.48h-9.62l-1.58,3.48c-.13.32-.42.64-.93.64h-2.8c-.48,0-.74-.42-.55-.84Zm13.9-6.85l-3.22-7.08h-.1l-3.15,7.08h6.47Z"/>
        <path className="cls-1" d="m103.6,119.84c0-.32.26-.61.61-.61h2.96c.32,0,.61.29.61.61v18.05h8.21c.35,0,.61.29.61.61v2.64c0,.32-.26.61-.61.61h-11.78c-.35,0-.61-.29-.61-.61v-21.3Z"/>
        <path className="cls-1" d="m130.59,123.09h-4.92c-.36,0-.61-.29-.61-.61v-2.64c0-.32.26-.61.61-.61h14.06c.35,0,.61.29.61.61v2.64c0,.32-.26.61-.61.61h-4.92v18.05c0,.32-.29.61-.61.61h-2.99c-.32,0-.61-.29-.61-.61v-18.05Z"/>
        <path className="cls-1" d="m158.07,130.91l-7.34-10.75c-.26-.42,0-.93.51-.93h3.28c.26,0,.42.16.52.29l5.15,7.4,5.15-7.4c.1-.13.26-.29.52-.29h3.31c.52,0,.77.52.52.93l-7.43,10.72v10.27c0,.32-.29.61-.61.61h-2.96c-.35,0-.61-.29-.61-.61v-10.23Z"/>
      </g>
    </g>
    <g>
      <path className="cls-1" d="m270.94,0c-3.56,0-6.45,2.89-6.45,6.45s2.89,6.45,6.45,6.45,6.45-2.89,6.45-6.45-2.89-6.45-6.45-6.45Zm0,11.72c-2.91,0-5.27-2.36-5.27-5.27s2.36-5.27,5.27-5.27,5.27,2.36,5.27,5.27-2.36,5.27-5.27,5.27Z"/>
      <path className="cls-1" d="m271.08,7.31h-1.11v2.48h-1.4V3.01h2.52c.8,0,1.42.18,1.85.54s.65.86.65,1.51c0,.46-.1.85-.3,1.16s-.5.56-.91.74l1.47,2.77v.07h-1.5l-1.27-2.48Zm-1.11-1.13h1.13c.35,0,.62-.09.82-.27s.29-.42.29-.74-.09-.57-.27-.75-.46-.27-.84-.27h-1.12v2.04Z"/>
    </g>
  </svg>
    )}
  
  export default Exp;
  