import React from "react";
import Banner from "./Banner";
import Table from "./Table";

const Article: React.FC<{
    bg: string,
    article:Array<string>,
    setNav:(val:number)=>void
}> = ({bg,article,setNav}): JSX.Element  => {
    const content = article.map((a:string,i:number)=>{
        const re = /\d./
        if(a.slice(0,3) === 'TTT'){
            const values = a.slice(3).split('/tt')
            const matrix = values.map((x:string)=> x.split(','))
            return <table><Table matrix={matrix} /></table>
        }
        else if (i+1 === article.length){
          return <div key={'article-'+i}>
            <span className='b'>{a.slice(0,5)}</span>&emsp;
            <span className="i">{a.slice(8)}</span>
          </div>
        }
        else if (a.slice(0,3).match(re)){
          return <div key={'article-'+i} className={i===0?'title p':"p row"}>&emsp;&emsp;<div className='num-list'>{a.split(' ')[0]}</div>{ a.slice(a.indexOf(' '))}</div>
        }
        else if (a.slice(0,3) === '///'){
          return <div key={'article-'+i} className={i===0?'title p':"p row b i"}>&emsp;{ a.slice(a.indexOf(' '))}</div>
        }
        else if (!a.includes('.')){
          return <p key={'article-'+i} className={i===0?'title':"p u"}>{a}</p>
        }
        else{
            return <p key={'article-'+i} className={i===0?'title p':"p"}>&emsp;{a}</p>
        }
    })

  return (
    <div className='bg-gradient banner-outer'>
      <Banner bg={bg} />
      <div className="search-row">
        <button className="back-btn" onClick={() => setNav(1)}>
          {"< Back"}
        </button>
        <div></div>
        <div></div>
      </div>
      <div className= 'article-box scroll'>
        {content}
        </div>
    </div>
  );
};

export default Article;
