import React from 'react'

const Search: React.FC<{searchInput:string,setSearchInput:(val:string)=>void}> = ({searchInput,setSearchInput}) => {
  return (
    <div className='search-container'>Search:
        <input className='search-box' value={searchInput} onChange={(e)=>setSearchInput(e.target.value)}/>
    </div>
  )
}

export default Search