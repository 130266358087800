import React from "react";
import { bodyObj } from "./interfaces/interfaces";

const Signup: React.FC<{
  body: bodyObj;
  legalNameValid: boolean;
  phoneValid: boolean;
  emailValid: boolean;
  reasonValid: boolean;
  setBody: (val: bodyObj) => void;
  legalNameCheck: (val: string) => void;
  phoneCheck: (val: string) => void;
  emailCheck: (val: string) => void;
  reasonCheck: (val: string) => void;
  submitForm: () => void;
}> = ({
  body,
  legalNameValid,
  phoneValid,
  emailValid,
  reasonValid,
  setBody,
  legalNameCheck,
  phoneCheck,
  emailCheck,
  reasonCheck,
  submitForm,
}) => {
  return (
    <form className="inquiry-form">
      <h3 className="form-title">Inquiry</h3>
      <label className="form-row">
        <h4>Full Legal Name:</h4>
        <input
          id="legal_name"
          className={legalNameValid ? "" : "red-bg"}
          onChange={(e) => {
            if (e.target.value.length <= 40) {
              setBody({ ...body, legal_name: e.target.value });
              legalNameCheck(e.target.value);
            } else
              alert("Please use middle initials rather than full middle name.");
          }}
        />
      </label>
      <label className="form-row">
        <h4>Preferred Name:</h4>
        <input
          id="preferred_name"
          onChange={(e) => {
            if (e.target.value.length <= 15) {
              setBody({ ...body, preferred_name: e.target.value });
            }
          }}
          value={body.preferred_name}
        />
      </label>
      <label className="form-row">
        <h4>Phone:</h4>
        <input
          id="phone"
          className={phoneValid ? "" : "red-bg"}
          onChange={(e) => {
            if (
              e.target.value
                .replaceAll("-", "")
                .replace("(", "")
                .replace(")", "")
                .replaceAll(" ", "")
                .trim().length <= 10
            ) {
              setBody({ ...body, phone: e.target.value });
              phoneCheck(e.target.value);
            }
          }}
          value={body.phone}
        />
      </label>
      <label className="form-row">
        <h4>Email:</h4>
        <input
          id="email"
          className={emailValid ? "" : "red-bg"}
          onChange={(e) => {
            if (e.target.value.length <= 50) {
              setBody({ ...body, email: e.target.value });
              emailCheck(e.target.value);
            } else {
              alert(
                "Please use another email, as the maximum length for this field is 50 characters."
              );
            }
          }}
          value={body.email}
        />
      </label>
      <label className="form-col">
        <h4>Tell me what brought you here:</h4>
        <textarea
          className={reasonValid ? "form-text" : "form-text red-bg"}
          rows={4}
          id="reason"
          onChange={(e) => {
            if (e.target.value.length <= 1000) {
              setBody({ ...body, reason: e.target.value });
              reasonCheck(e.target.value);
            } else {
              alert("This field has a maximum length of 1000 characters.");
            }
          }}
          value={body.reason}
        />
      </label>
      {legalNameValid && phoneValid && emailValid && reasonValid ? (
        <button type="button" className="submit" onClick={() => submitForm()}>
          Submit
        </button>
      ) : (
        <button type="button" className="submit-disabled">
          Submit
        </button>
      )}
    </form>
  );
};

export default Signup;
