import React from 'react'
import './App.css'
import { handleClick } from './helpers/setClassHelpers';
import MainNav from './Subnav/MainNav';

const Header: React.FC<{navSelect:number,setPrev:(val:number) => void,setNav:(val:number)=>void}> = ({navSelect, setPrev,   setNav }): JSX.Element => {
  
  return (
    <header className="App-header" onClick={(e)=>handleClick(e,setNav,setPrev)}>
          <h1>S. Kyle Dodd</h1>
          <MainNav navSelect = {navSelect}/>
        </header>
  )
}

export default Header