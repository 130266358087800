import React from "react";
import Header from "./Header";
const Iabs = require('./images/Stephen Kyle Dodd - eXp - IABS.pdf')
const Cpn = require('./images/Texas CPN.pdf')

const content = require("./content/landing.json")

const Landing: React.FC<{
  navSelect: number;
  bg: string;
  setPrev: (val: number) => void;
  setNav: (val: number) => void;
}> = ({ navSelect, bg, setNav, setPrev }): JSX.Element => {


  const getContent = (outer:Array<string>) => {
    const data = []
    let idx = 0
    for(let inner of outer){
      const children:Array<React.ReactNode> = []
        if(inner.length-1){
          let i = 0
          for(let text of inner){
            if (i===0){
              if(text.slice(0,1) === "#"){
                children.push(<span key={'span'+i} className="indent bold">{text.slice(1)}<br/><br/></span>)

              } else if(text.slice(0,1) === "*"){
                children.push(<ul><li key={'span'+i} className="indent">{text.slice(1)}</li></ul>)
              }
              else{
                children.push(<span key={'span'+i} className="indent">{text}<br/><br/></span>)
              }

            }
            else if(i<inner.length -1){
              if(text.slice(0,1) === "#"){
                children.push(<span key={'span'+i} className="indent bold">&emsp;&emsp;{text.slice(1)}<br/><br/></span>)

              } else if(text.slice(0,1) === "*"){
                children.push(<ul><li key={'span'+i} className="indent">{text.slice(1)}</li></ul>)
              }
              else{
                children.push(<span key={'span'+i} className="indent">&emsp;&emsp;{text}<br/><br/></span>)              
              }
            }
            else{
              if(text.slice(0,1) === "#"){
                children.push(<span key={'span'+i} className="indent bold">&emsp;&emsp;{text.slice(1)}</span>)

              } else if(text.slice(0,1) === "*"){
                children.push(<ul><li key={'span'+i} className="indent">{text.slice(1)}</li></ul>)
              }
              else{
                children.push(<span key={'span'+i} className="indent">&emsp;&emsp;{text}</span>)              
              }
              
            }
            
            i++
          }
          const pElement = React.createElement(
            'p',
            {className:'p-plus',key: 'p'+idx},
            children
          )

          data.push(
            pElement
          )
        }
        else{
          if(inner[0].slice(0,1) === "#"){
            data.push(
              <p className="p-plus" key={'p'+idx}>
                <span className='bold'>{inner[0].slice(1)}</span>
              </p>
            )
          }
          else{
          data.push(
            <p className="p-plus" key={'p'+idx}>
              {inner[0]}
            </p>
          )
        }
        }
        idx++
      }
    return data
  }
  
  const idx = Math.round(Math.random()*0)
  return (
    <div className={'bg0'+bg.slice(2) + " App-alt"}>
      <Header navSelect={navSelect} setPrev={setPrev} setNav={setNav} />
      <article className="article-light">
        {getContent(content[idx])}
      </article>
      <a className='iabs' href = {Iabs} target = "_blank">Texas Real Estate Commission Information About Brokerage Services</a>
      <span className="iabs">&ensp;&&ensp;</span>
      <a className='iabs' href = {Cpn} target = "_blank">Consumer Notice</a>
    </div>
  );
};

export default Landing;
