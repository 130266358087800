import React, { useEffect, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import "./App.css";
import Header from "./Header";
import me from "./images/me.jpg";
import myFace from "./images/my-face-no-bg-2.jpeg";
import HomeSearchForm from "./RealEstate/HomeSearchForm";
import { submitInquiryForm } from "./network/inquiry";
import HomeSubNav from "./Subnav/HomeSubNav";
import nav from "./constants/nav";
import BusinessCard from "./BusinessCard";
import { handleClick } from "./helpers/setClassHelpers";
import Plot from 'react-plotly.js';
import MortgageCalculator from "./RealEstate/MortgageCalculator";
import ValuesChart from "./RealEstate/ValuesChart";
import ValuesMobile from "./RealEstate/ValuesMobile";
import Trend from "./RealEstate/Trend";
import LocationMap from "./RealEstate/LocationMap";
import ListSubNav from "./Subnav/ListSubNav";
const home = require("./content/home.json");

const Home: React.FC<{
    navSelect: number;
    bg: string;
    setPrev: (val: number) => void;
    setNav: (val: number) => void;
    setUser: (val: any) => void;
}> = ({ navSelect, bg, setPrev, setNav, setUser }): JSX.Element => {
    const [legalNameValid, setLegalNameValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [selectedSqft, setSelectedSqft] = useState(false);
    const [selectedAcre, setSelectedAcre] = useState(false);
    const [selectedBeds, setSelectedBeds] = useState(false);
    const [selectedBath, setSelectedBath] = useState(false);
    const [targetPriceValid, setTargetPriceValid] = useState(false);
    const [checked, setChecked] = useState(false);
    const [subNavSelect, setSubNav] = useState(navSelect*-1*10-(navSelect*-1)**2+(navSelect+2));
    const [body, setBody] = useState({
        legal_name: "",
        preferred_name: "",
        email: "",
        phone: "",
        reason: "N0000.00000.00",
        submission_date: String(Date.now())
    });

    const submitForm = async (): Promise<void> => {
        const json = await submitInquiryForm(body);
        const result = await json.json();
        if (result.response.includes("@")) {
            let request = window.confirm(
                "Inquiry successful. Would you like to request a call?"
            );
            if (request) {
                setNav(4);
                setUser(result.response);
            } else {
                let request = window.confirm(
                    "Would you like to browse listings?"
                );
                if (request) {
                    window.open("https://stephendodd.exprealty.com", "_blank");
                }
            }
        } else {
            throw new Error(result.Error);
        }
        // .catch((err) => alert(err + "\nPlease try again."))
    };

    const legalNameCheck = (value: string): void => {
        if (value.indexOf(" ") > 0) {
            const regExp = new RegExp(/[A-Za-z,]{2,37}\s[A-Za-z]{2,37}/g);
            if (value.replace(",", "").trim().match(regExp)) {
                setLegalNameValid(true);
            } else setLegalNameValid(false);
        } else setLegalNameValid(false);
    };
    const phoneCheck = (value: string): void => {
        let phone = value
            .replaceAll("-", "")
            .replace("(", "")
            .replace(")", "")
            .replaceAll(" ", "")
            .trim();
        if (String(parseInt(phone)) === phone && phone.length === 10) {
            setPhoneValid(true);
        } else setPhoneValid(false);
    };
    const emailCheck = (value: string): void => {
        const regExp = new RegExp(
            /[A-Za-z\d]{1,45}@[A-Za-z\d]{1,45}\.[A-Za-z]{2,4}/g
        );
        if (value.match(regExp)) {
            setEmailValid(true);
        } else setEmailValid(false);
    };
    const validPriceCheck = (price: string) => {
        if (parseInt(price) >= 10_000) {
            setTargetPriceValid(true);
            return true;
        } else {
            setTargetPriceValid(false);
            return false;
        }
    };
    const scrollToBottom = () => {
        const bottom = window.innerHeight;
        window.scrollTo(0, bottom);
    };

    const content = home[navSelect + 2]
        .slice(2)
        .map((text: string, i: number) => {
            return (
                <div className="story" key={"story-" + i}>
                    <p className="p">&emsp;{text}</p>
                </div>
            );
        });
    console.log(window.innerWidth)
    return (
        <>
            <div
                className={
                    "App bg" +
                    (((parseInt(bg.slice(2)) + (navSelect + 2) * 5)) % 10)
                }
            >
                <Header
                    navSelect={navSelect}
                    setPrev={setPrev}
                    setNav={setNav}
                />
                <div id="first-section-content">
                    <h3>{home[navSelect + 2][1]}</h3>
                    <div className="down" onClick={() => scrollToBottom()}>
                        {<AiOutlineDown color="teal" />}
                    </div>
                </div>
            </div>
            <main>
                <section className="section">
                    <div>{home[navSelect + 2][0]}</div>
                </section>
                <section className="section-alt">
                    <div onClick={(e)=>handleClick(e,setSubNav,setPrev)}>
                    {navSelect === -1 ? (
                        <HomeSubNav subNavSelect={subNavSelect}/>
                    ) : (
                        <ListSubNav subNavSelect={subNavSelect}/>
                    )}
                    </div>
                </section>
                <div
                    className={
                        window.innerWidth > 900 ? "row" : "space space-right"
                    }
                >
                    <div className="article-container">
                        {subNavSelect === 10 && <article className="article">{content}</article>}
                        {subNavSelect === 11 && <Trend/>}
                        {subNavSelect === 12 && <MortgageCalculator/>}
                        {subNavSelect === 13 && window.innerWidth > 750 && <ValuesChart/>}
                        {subNavSelect === 13 && window.innerWidth <= 750 && <ValuesMobile/>}
                        {subNavSelect === 14 && <article className="article">
                            <p className="p">&emsp;We live in the digital age. Embrace the chagne 
                            with me, and eXp. As a technology leveraged brokerage, eXp is a platform
                            that is designed with technology first. This leaves realtors like me with
                            the time to focus on the business of selling your home. Put your trust in
                            myself and eXp to get your home sold for what it should bring.
                            </p><br/>
                            <p className="p">&emsp;From advertising and leveraging search engine optimization, to 
                            driving potential buyers to your listing through effective marketing,
                            my team at eXp and I will work from the moment we list your home, until
                            the minute it sells to ensure you get the outcome you hope for.
                            </p><br/>
                            <p className="p">&emsp;Think about where most people go to buy most things today. Amazon
                            likely comes to your mind. The qualities taht make Amazon so effective are
                            similar to those that make eXp so effective. An elegantly styled search
                            feature, sites optimized to pull from google search results, digital 
                            marketing where it matters most.
                            </p><br/>
                            <p className="p">&emsp;When you list your home with me, you'll get the added benefit of a
                            dedicated webpage to ensure that your home gets the attention it deserves.
                            More than that, I will use my expertise in the real estate market and also 
                            leverage my data science background to better inform you about an optimal
                            list price.
                            </p>
                            </article>}
                        {subNavSelect === 15 && <LocationMap />}
                        {subNavSelect === 16 && <article className="article">{content}</article>}
                        
                        {subNavSelect === 17 && <iframe
                            width={window.innerWidth > 800 ? "850" : "425"}
                            height={window.innerWidth > 800 ? "480" : "240"}
                            src={`https://www.youtube.com/embed/z0ak-f4qlFU`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                            />
                        }
                        {subNavSelect === 18 && <article className="article">
                            <p className="p">&emsp;In the dynamic world of real estate, where properties constantly 
                            change hands and market trends evolve, effective marketing plays a pivotal role in 
                            securing success for both buyers and sellers. A well-executed marketing strategy not 
                            only showcases a property's unique features but also establishes a strong brand 
                            presence for real estate professionals. Let's explore how strategic marketing can 
                            transform the landscape of the real estate industry.
                            </p>
                            <p className="p">&emsp;First and foremost, understanding the target audience is 
                            paramount. Whether it's a luxurious penthouse or a cozy suburban home, identifying 
                            the demographics, interests, and preferences of potential buyers allows real estate 
                            marketers to tailor their strategies for maximum impact. By crafting compelling 
                            narratives that resonate with the target market, real estate professionals can evoke 
                            emotions and create a connection that goes beyond the physical attributes of the 
                            property.
                            </p>
                            <p className="p">&emsp;Digital platforms have become the cornerstone of real estate 
                            marketing, offering a vast and accessible landscape for reaching potential buyers. 
                            Leveraging social media, search engine optimization (SEO), and online listings, real 
                            estate marketers can showcase properties to a global audience. High-quality visuals, 
                            immersive virtual tours, and engaging content contribute to a property's online 
                            allure, making it stand out in a crowded market.
                            </p>
                            <p className="p">&emsp;Innovative technologies, such as augmented reality (AR) and 
                            virtual reality (VR), are revolutionizing the real estate marketing landscape. 
                            These technologies provide prospective buyers with immersive experiences, allowing 
                            them to virtually tour a property from the comfort of their homes. This not only 
                            saves time for both buyers and sellers but also enhances the overall marketing 
                            experience.
                            </p>
                            <p className="p">&emsp;Networking remains a powerful tool in real estate marketing. 
                            Establishing and nurturing relationships with other professionals in the industry, 
                            including real estate agents, mortgage brokers, and home inspectors, can lead to 
                            valuable referrals. Collaborative marketing efforts, such as co-hosted events or 
                            cross-promotions, amplify the reach and impact of marketing campaigns.
                            </p>
                            <p className="p">&emsp;In conclusion, strategic marketing is the driving force 
                            behind successful real estate transactions. By embracing the latest technologies, 
                            understanding the target audience, and fostering collaborative relationships, real 
                            estate professionals can elevate their marketing efforts, making a lasting impact 
                            in an ever-evolving industry. In the competitive world of real estate, a 
                            well-crafted marketing strategy is the key to unlocking doors and turning 
                            properties into homes.
                            </p>
                            </article>}
                        {
                            subNavSelect === 19 && <><h1 id='process'>POLISH Program Steps</h1><img className='polish-chart' src={require('./images/POLISH.jpg')}/></>
                        }
                        {navSelect === -1 && (
                            <HomeSearchForm
                                body={body}
                                legalNameValid={legalNameValid}
                                phoneValid={phoneValid}
                                emailValid={emailValid}
                                selectedSqft={selectedSqft}
                                selectedAcre={selectedAcre}
                                selectedBeds={selectedBeds}
                                selectedBath={selectedBath}
                                targetPriceValid={targetPriceValid}
                                checked={checked}
                                setBody={setBody}
                                legalNameCheck={legalNameCheck}
                                phoneCheck={phoneCheck}
                                emailCheck={emailCheck}
                                validPriceCheck={validPriceCheck}
                                setTargetPriceValid={setTargetPriceValid}
                                setSelectedSqft={setSelectedSqft}
                                setSelectedAcre={setSelectedAcre}
                                setSelectedBeds={setSelectedBeds}
                                setSelectedBath={setSelectedBath}
                                setChecked={setChecked}
                                setUser={setUser}
                                submitForm={submitForm}
                            />
                        )}
                        {/* <button
                            className="consult-btn"
                            onClick={() => {
                                submitForm()
                            }}
                        >
                            Request Free Consultation
                        </button> */}
                        {navSelect === -1 ? legalNameValid &&
                        phoneValid &&
                        emailValid &&
                        selectedAcre &&
                        selectedBath &&
                        selectedBeds &&
                        selectedSqft ? (
                            <button
                                type="button"
                                id="animated-submit"
                                className="submit"
                                onClick={() => submitForm()}
                            >
                                Submit
                            </button>
                        ) : (
                            <button type="button" className="submit-disabled">
                                Submit
                            </button>
                        ):<></>}
                    </div>
                    <aside className="aside">
                        <div className="fade-border">
                            <img
                                className={
                                    window.innerWidth <= 900
                                        ? "content-center round face"
                                        : "round face"
                                }
                                src={myFace}
                            />
                        </div>
                        <BusinessCard/>
                        
                        
                        <img
                            className={
                                window.innerWidth <= 900
                                    ? "content-center self"
                                    : "space self"
                            }
                            src={me}
                        />
                    </aside>
                </div>
            </main>
        </>
    );
};

export default Home;
