
export const getWeekDay = (num:number) => num.toString()+'-'+['sunday','monday','tuesday','wednesday','thursday','friday','saturday'][num]

export const getChangeDay = (day:number, month:number, year:number):number =>{
    let changeDay
    if ([1, 3, 5, 7, 8, 10, 12].includes(month) && day > 24) {
        changeDay = 31;
    } else if ([4, 6, 9, 11].includes(month) && day > 23) {
        changeDay = 30;
    } else if (
        [2024, 2028, 2032, 2036, 2040, 2044, 2048].includes(year)
    ) {
        changeDay = 29;
    } else {
        changeDay = 28;
    }
    return changeDay
}

export const getFormattedDateString = (month:number|string,day:number|string,year:number|string) => month.toString() + "/" + day + "/" + year
export const getDbFormattedDate = (month:number|string,day:number|string,year:number|string) => month.toString() + day + year