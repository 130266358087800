import React from 'react'
import { PiHandHeartBold } from 'react-icons/pi'
import { GiFamilyHouse, GiArchiveResearch } from 'react-icons/gi'
import { TbHomeDollar } from 'react-icons/tb';
import { MdSendToMobile } from "react-icons/md";
import { TiBusinessCard } from "react-icons/ti";
import Logo from '../Logo'
import Exp from '../Exp';
import Realtor from '../images/realtor-com-logo.png'
import { setClass, handleClick } from '../helpers/setClassHelpers';

const MainNav: React.FC<{navSelect:number}> = ({navSelect}) => {
    return (
        <nav>
          <div className='nav-row'>
            <div id='tt-0' className = 'tooltip'>
              <span id='st-0' className='tooltiptext'>Home</span>
              <button id='bt-0' className={setClass(navSelect,0)}>
                <Logo/>
              </button>
            </div>  
            <div id='tt--2' className = 'tooltip'>
              <span id='st--2' className='tooltiptext'>List Home</span>
              <button id='bt--2' className={setClass(navSelect,-2)}>
                <TbHomeDollar size ={36}/>
              </button>
            </div>
            <div id='tt--1' className = 'tooltip'>
              <span id='st--1' className='tooltiptext'>Real Estate</span>
              <button id='bt--1' className={setClass(navSelect,-1)}>
                <GiFamilyHouse size={36}/>
              </button>
            </div>
            <div id='tt-5' className = 'tooltip'>
              <span id='st-5' className='tooltiptext'>About me</span>
              <button id='bt-5' className={setClass(navSelect,5)}>
                <TiBusinessCard size={36}/>
              </button>
            </div>
          </div>
          <div className='nav-row'>
            <div className = 'tooltip'>
              <span className='tooltiptext' onClick={() => {window.open('https://stephendodd.exprealty.com', '_target')}}>Realty</span>
              <button className='nav-btn-exp' onClick={() => {window.open('https://stephendodd.exprealty.com', '_target')}}>
                <Exp />
              </button>
            </div>  
            <div className = 'tooltip'>
              <span className='tooltiptext' onClick={() => {window.open('https://www.realtor.com/realestateagents/656e9b0902b90e839dfc9787', '_target')}}>Realtor .com</span>
              <button className='nav-btn-realtor' onClick={() => {window.open('https://www.realtor.com/realestateagents/656e9b0902b90e839dfc9787', '_target')}}>
                <img id='realtor-com-logo' src={Realtor} onClick={() => {window.open('https://www.realtor.com/realestateagents/656e9b0902b90e839dfc9787', '_target')}}/>
              </button>
            </div>  
            <div id='tt-1' className = 'tooltip'>
              <span id='st-1' className='tooltiptext'>Articles</span>
              <button id='bt-1' className='nav-btn'>
                <GiArchiveResearch size={36}/>
              </button>
            </div>
            <div id='tt-3' className = 'tooltip'>
              <span id='st-3' className='tooltiptext'>Inquiry</span>
              <button id='bt-3' className='nav-btn'>
                <MdSendToMobile size={36}/>
              </button>
            </div>
          </div>
          </nav>
  )
}

export default MainNav