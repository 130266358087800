export const setClass = (selected:number,id: number) => {
    if (selected === id) {
      return "nav-btn-selected";
    } else {
      return "nav-btn";
    }
  };

export const handleClick = (
    e:React.MouseEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>,
    setNav:(val:number)=>void,
    setPrev:(val:number)=>void
    ) => {
    const eventTarget =  e.target as HTMLElement;
    let id = 0
    if(eventTarget.parentElement?.tagName == 'svg'){
      const parent = eventTarget.parentElement?.parentElement
      if(parent){
        id = parseInt(parent.id.slice(3)) || 0
      }
    }
    else if(eventTarget.tagName == 'svg'){
      const parent = eventTarget.parentElement
      if(parent){
        id = parseInt(parent.id.slice(3)) || 0
      }
    }
    else {
      id = parseInt(eventTarget.id.slice(3)) || 0
    }
    setNav(id)
    if(id <= 0){
      setPrev(id)
    }
  }