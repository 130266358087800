import React, { useState } from 'react'
import Plot from 'react-plotly.js';

const ValuesChart = () => {
    const [showExplanation,setShowExplanation] = useState(false)
    const homeOwnershipValues = [2, 6, 6, 2];
    const rentingValues = [4,2,2,4];
    return (<div className='values-plot'><Plot
                            data={[
                                {
                                x: [1,2,3,4],
                                y: homeOwnershipValues,
                                mode: 'text+markers',
                                type: 'scatter',
                                name: 'Home Ownership',
                                text:['stability','hedge inflation','equity','customize'],
                                marker: {
                                    size: [60,110,130,70], // Use square root to scale bubble size
                                    color: 'rgba(44, 128, 128, 0.7)',
                                },
                                },
                                {
                                x: [1,2,3,4],
                                y: rentingValues,
                                mode: 'text+markers',
                                type: 'scatter',
                                name: 'Renting',
                                text:['flexibility','lower expenses','easy exit','no repairs'],
                                
                                marker: {
                                    size: [110,100,60,100], // Use square root to scale bubble size
                                    color: 'rgba(240, 210, 90, 0.7)',
                                },
                                },
                            ]}
                            layout={{
                                width: 800,
                                height: 500,
                                title: 'Value of Home Ownership vs. Renting',
                                xaxis: { title: 'Benefit' },
                                yaxis: { title: 'Time to gain benefit' },
                                showlegend: true,
                            }}
                            />{
                                showExplanation ?
                                <div className='explanation'><div>Explanation: The bubble chart demonstrates 
                                the importance of benefits associated with 
                                leasing vs purchasing and the time to realize 
                                those benefits. The absolute time is not
                                represented, as we can't put hard numbers,
                                but rather time relative to the comparison.
                                <br/><br/>1. The flexibility that comes from
                                renting is not as quickly realized as buying
                                a home leads to stability. A lease is for a
                                specified period of time, and thus breaking
                                the lease without any negative consequences
                                does take the period of time the lease is
                                written for. The stability of a home 
                                purchase on the otherhand is almost immediate.
                                Once purchased, the home belongs to the home 
                                owner, unless they default on the mortgage 
                                or don't pay the taxes, etc.
                                <br/><br/>2. Inlation, usually, is a slow
                                accumulation. In 20 years your mortgage
                                payment, if you haven't paid it off, will
                                be the same as it was 20 years earlier. At
                                that point it will, almost certainly, cost 
                                less than the rent price of a similar 
                                property. Generally, renting will mean
                                lower insurance costs, avoiding homeowners
                                insurance altogether and utilities are 
                                shared in some sense. For example, the cost 
                                of heating and cooling an apartment is
                                positively impacted by the fact that two to 
                                three walls will face other units that are
                                also heated or cooled, reducing cost.
                                <br/><br/>3. It takes much longer to gain 
                                equity in a home, than it does to be able to
                                exit a lease. Compared with home ownership 
                                though, breaking a lease is much easier and 
                                less costly than getting out of the 
                                mortgaged home.<br/><br/>4. To realize the
                                impact of not making repairs would also
                                generally be a benefit that is realized when
                                something breaks, which is unlikely to be
                                shortly after move in. A purchased home
                                is immediately customizable, as long as
                                customizations don't violate city ordinances,
                                HOA bylaws, etc.</div><br/>
                                <button onClick={()=> setShowExplanation(!showExplanation)}>&#9650; Collapse</button>
                                </div>:
                                <div className='explanation-expand'>
                                    <button onClick={()=> setShowExplanation(!showExplanation)}>&#9660; Explanation</button>
                                </div>}
                                </div>
  )
}

export default ValuesChart