import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import {
    getChangeDay,
    getDbFormattedDate,
    getFormattedDateString,
    getWeekDay,
} from "./helpers/scheduleHelpers";

const Schedule: React.FC<{
    bg: string;
    navPrev: number;
    user:string|null;
    setNav: (val: number) => void;
}> = ({ bg, navPrev, user, setNav }) => {
    const today = new Date();
    const [body, setBody] = useState({
        schedule_date: "",
        schedule_time: "",
        email: user,
    });
    const [weekday, setWeekday] = useState(today.getDay().toString() as any);
    const [hours, setHours] = useState([[], [], [], [], [], [], []] as Array<
        Array<any>
    >);
    const [openDates, setDates] = useState([
        <option>Loading...</option>,
    ] as Array<React.ReactNode>);
    const [currentHours, setCurrHours] = useState([
        <option>Loading...</option>,
    ]);
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const getHourOption = (hour: number) => {
        const hourString = hour.toString().padStart(2, "0");
        return (
            <option key={'time'+hour} value={hourString + ":00" + (hour < 9 ? "P" : "A")}>
                {hourString + ":00" + (hour < 9 ? "P" : "A")}
            </option>
        );
    };
    const getHours = () => {
        const weekday = today.getDay();
        const hoursArr = [];
        for (let d of [1, 2, 3, 4, 5, 6, 7]) {
            const defHour = <option value="">Select...</option>;
            const hourArr = [defHour];
            if ([1, 2, 3, 4].includes(weekday + (d % 7))) {
                for (let hour of [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7]) {
                    hourArr.push(getHourOption(hour));
                }
            } else {
                for (let hour of [9, 10, 11, 12, 1, 2, 3]) {
                    hourArr.push(getHourOption(hour));
                }
            }
            hoursArr.push(hourArr);
        }
        setHours(hoursArr);
    };
    const getDateOption = (i:number,dayString:string,month:string,day:string,year:string) =>{
        return(
            <option key={'date'+i}
                            value={
                                i.toString() +
                                "-" +
                                getDbFormattedDate(month, day, year)
                            }
                        >
                            {dayString +
                                " - " +
                                getFormattedDateString(
                                    month,
                                    day,
                                    year
                                )}
                        </option>
        )
    }
    function getDates() {
        const weekday = today.getDay();
        const dates = [<option disabled>Select...</option>];
        for (let d of [0, 1, 2, 3, 4, 5, 6]) {
            const changeDay = getChangeDay(day, month, year);
            const dow = (weekday + d + 1) % 7;
            const value = getWeekDay(dow);
            const dayStrLower = value.slice(2, value.length);
            const dayString =
                dayStrLower.slice(0, 1).toUpperCase() + dayStrLower.slice(1);
            const thisDay = d + day;
            const twoDigitDay = thisDay.toString().padStart(2, "0");
            const twoDigitMonth = month.toString().padStart(2, "0");
            const twoDigitChangeDay = (thisDay - changeDay)
                .toString()
                .padStart(2, "0");
            const twoDigitChangeMonth = (month + 1).toString().padStart(2, "0");
            const twoDigitYear = (year % 2000).toString();
            if (month === 12 && day > 24) {
                if (thisDay <= 31) {
                    dates.push(getDateOption(d,dayString,'12',twoDigitDay,twoDigitYear));
                } else {
                    dates.push(getDateOption(d,dayString,'01',twoDigitChangeDay,twoDigitYear+1));
                }
            } else if (d + day <= changeDay) {
                dates.push(getDateOption(d,dayString,twoDigitMonth,twoDigitDay,twoDigitYear));
            } else {
                dates.push(getDateOption(d,dayString,twoDigitChangeMonth,twoDigitChangeDay,twoDigitYear));
            }
        }
        setDates(dates);
    }
    useEffect(() => {
        getHours();
        getDates();
        setWeekday(parseInt(weekday));
    }, []);
    useEffect(() => {
        setCurrHours(hours[weekday]);
    }, [weekday]);
    const submitForm = () => {
        fetch("https://mathemagical-server.herokuapp.com/inquiry", {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.response === "success") {
                    alert("Inquiry successful");
                    setNav(navPrev);
                } else {
                    throw new Error(res.error);
                }
            })
            .catch((err) => alert(err + "\nPlease try again."));
    };
    return (
        <div className="bg-gradient banner-outer">
            <Banner bg={bg} />
            <button className="back-btn m-1" onClick={() => setNav(navPrev)}>
                {"< Back"}
            </button>
            <div className="inquiry-page">
                <div className="schedule-image">
                    <form className="schedule-form">
                        <h3 className="form-title">Schedule</h3>
                        <label className="form-select-row">
                            <h4>Date:</h4>
                            <select
                                id="date_select"
                                onChange={(e) => {
                                    setWeekday(
                                        parseInt(e.target.value.slice(0, 1))
                                    );

                                    let date = e.target.value.slice(2);
                                    if (date?.length)
                                        setBody({
                                            ...body,
                                            schedule_date: date,
                                            schedule_time: "",
                                        });
                                }}
                            >
                                {openDates}
                            </select>
                        </label>
                        <label className="form-select-row">
                            <h4>Time:</h4>
                            <select
                                id="time_select"
                                onChange={(e) => {
                                    let time = e.target.value;
                                    setBody({ ...body, schedule_time: time });
                                }}
                                value={body.schedule_time}
                            >
                                {currentHours}
                            </select>
                        </label>
                        {body.schedule_date &&
                        body.schedule_time &&
                        body.email ? (
                            <button
                                type="button"
                                className="submit"
                                onClick={() => submitForm()}
                            >
                                Submit
                            </button>
                        ) : (
                            <button type="button" className="submit-disabled">
                                Submit
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Schedule;
