// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgV6RD5GpZJz1O-kqiq5sP68ht5pyDmk4",
  authDomain: "customers-d37a1.firebaseapp.com",
  projectId: "customers-d37a1",
  storageBucket: "customers-d37a1.appspot.com",
  messagingSenderId: "842120075550",
  appId: "1:842120075550:web:92e97f35008916148a110f",
  measurementId: "G-Q7W4VE5TVX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);