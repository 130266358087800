import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import Signup from "./Signup";
import { db } from './firebase'
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
  } from "firebase/firestore";
const realtyCollectionRef = collection(db, "realty");
const Inquiry: React.FC<{
  bg: string;
  navPrev: number;
  setNav: (v: number) => void;
  setUser: (v: any) => void;
}> = ({ bg, navPrev, setNav,setUser }) => {
    const [body, setBody] = useState({'legal_name':'','preferred_name':'','email':'','phone':'','reason':'','submission_date':String(Date.now())})
    const [legalNameValid,setLegalNameValid] = useState(false)
    const [phoneValid,setPhoneValid] = useState(false)
    const [emailValid,setEmailValid] = useState(false)
    const [reasonValid,setReasonValid] = useState(false)
    useEffect(() => {
        if(navPrev === -1){
            setBody({...body,'reason':'I\'d like to discuss buying a home.'})
            setReasonValid(true)
        }
        if(navPrev === -2){
            setBody({...body,'reason':'I\'d like to discuss selling a home.'})
            setReasonValid(true)
        }
    }, [])
    const submitForm = async() => {
        const data = {
            ...body, 
            'reason': body.reason.replaceAll('<','').replaceAll('>','').replaceAll(';','.').replaceAll("'",'"'),
            'phone': body.phone.replaceAll('-','').replace('(','').replace(')','').replaceAll(' ','').trim()
        }
        let response = await addDoc(realtyCollectionRef, {...data})
        // fetch('https://mathemagical-server.herokuapp.com/inquiry',
        // {
        //     method:'POST',
        //     mode: 'cors',
        //     headers:{
        //         "Content-Type": 'application/json'
        //     },
        //     body: JSON.stringify(data)
        // }).then(res=>res.json()).then(res=>{
        //     if(res.response.includes('@')){
        //         const request = window.confirm('Inquiry successful. Would you like to request a call?')
        //         if(request) {setNav(4); setUser(res.response)}
        //         else setNav(navPrev)
        //     }
        //     else {
        //         throw new Error(res.Error)
        //     }
        // }).catch(err=>alert(err+'\nPlease try again.'))
    };
    const legalNameCheck = (value:string):void =>{
        if(value.indexOf(' ')>0){
            const regExp = new RegExp(/[A-Za-z,]{2,37}\s[A-Za-z]{2,37}/g)
            if(value.replace(',','').trim().match(regExp)){
                setLegalNameValid(true)            }
            else setLegalNameValid(false)
        }
        else setLegalNameValid(false)
    }
    const phoneCheck = (value:string):void =>{
        let phone = value.replaceAll('-','').replace('(','').replace(')','').replaceAll(' ','').trim()
        if(String(parseInt(phone)) === phone && phone.length ===10){
            setPhoneValid(true)
        }
        else setPhoneValid(false)
    }
    const emailCheck = (value:string):void =>{
        const regExp = new RegExp(/[A-Za-z\d]{1,45}@[A-Za-z\d]{1,45}\.[A-Za-z]{2,4}/g)
        if(value.match(regExp)){
            setEmailValid(true)
        }
        else setEmailValid(false)
    }
    const reasonCheck = (value:string):void =>{
        const regExp = new RegExp(/[A-Za-z\d\s,'"-\.]{10,12}/g)
        if(value.match(regExp)){
            setReasonValid(true)
        }
        else setReasonValid(false)
    }
  return (
    <div className="bg-gradient banner-outer">
      <Banner bg={bg} />
      <button className="back-btn m-1" onClick={() => setNav(navPrev)}>
        {"< Back"}
      </button>
      <div className="inquiry-page">
        <div className="inquiry-image">
            <Signup 
                body={body} 
                legalNameValid={legalNameValid}
                phoneValid={phoneValid}
                emailValid={emailValid}
                reasonValid={reasonValid}
                setBody={setBody}
                legalNameCheck={legalNameCheck}
                phoneCheck={phoneCheck}
                emailCheck={emailCheck}
                reasonCheck={reasonCheck}
                submitForm={submitForm}
            />
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
