import React from 'react'
import { MdOutlineStarPurple500 } from 'react-icons/md'
import Logo from './Logo'

const BusinessCard = () => {
  return (
    <div className="card space">
        <div className="card-row">
            <MdOutlineStarPurple500
                color="rgb(249, 243, 151)"
                size={35}
            />
            <MdOutlineStarPurple500
                color="rgb(249, 243, 151)"
                size={35}
            />
        </div>
        <Logo />
        <p className="p">
            {" "}
            Call today for a free consultation:
        </p>
        <p className="p">(918) 550-5110</p>
        <p className="p">- or - send an email:</p>
        <p className="p">skdodd@live.com</p>
        <p className="p">
            <MdOutlineStarPurple500
                color="rgb(249, 243, 151)"
                size={35}
            />
            &emsp;Have a wonderful day!&emsp;
            <MdOutlineStarPurple500
                color="rgb(249, 243, 151)"
                size={35}
            />
        </p>
    </div>
  )
}

export default BusinessCard