import React from 'react'
import Plot from 'react-plotly.js'

const Trend = () => {
    const years = [
        1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 
        1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 
        2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012,
        2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 
        2023
        ]
    const w = window.innerWidth > window.innerHeight ? window.innerWidth-600 : window.innerWidth

  return (
    <Plot
    data={[
        {
        name: 'DJIA',
        x: years,
        y: [
            1230, 1185, 1514, 2075, 1900, 2300, 2775, 2500, 3265, 3270,
            3900, 3865, 5180, 6700, 7750, 9120, 10740, 10665, 9985, 8585,
            10570, 10430, 10960, 12555, 12100, 8075, 10065, 11675, 12425, 13650,
            15880, 17670, 16350, 19965, 25800, 24705, 28990, 31100, 36230, 34300,
            37305
        ],
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: 'rgb(44, 128, 128)' },
        },
        // Data from Fred
        {
            type: 'bar',
            name: 'Median Home Price',
            marker: { color: 'rgb(240, 210, 90)' },
            x: years,
            y: [
                86700, 94700, 98500, 106300, 120800, 137900, 144300, 149500, 151100, 144500,
                144700, 153600, 153500, 161100, 172200, 180000, 189100, 202900, 211000, 227600,
                233100, 262900, 288500, 305300, 322100, 290400, 257000, 275300, 268100, 278000,
                307400, 331400, 348000, 357000, 374800, 374600, 375500, 383000, 418600, 514100,
                505300
            ],
        },
        ]}
        layout={{
            width: w,
            height: 400,
            plot_bgcolor: 'whitesmoke',
            paper_bgcolor: 'rgba(255, 255, 255, 0.7)',
            legend: { xanchor: 'center', yanchor: 'bottom' },
            title: 'Annual Median Home Price vs DJIA Price',
            xaxis: { title: 'Year' },
            yaxis: { title: 'Price' },
            margin: { l: 60, r: 10, t: 60, b: 50 }, // Adjust margins for better spacing
        }}
    />
  )
}

export default Trend