export default {
    LIST:-2,
    REALTY:-1,
    LANDING:0,
    CONTENT:1,
    ARTICLE:2,
    INQUIRY:3,
    SCHEDULE:4,
    ABOUTME:5,
    MAIN:10,
    HOME_TREND:11,
    MORT_CALC:12,
    HOME_BENE:13,
    HOME_TECH:14,
    LOCATION:15,
    SELL:16,
    CMA:17,
    MARKETING:18,
    PRICING:19,
    }
